import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { ApplicationView } from '_src/app/application';

export default ApplicationView;

/**
 * By using getStaticProps for locale files, we can still have the translations
 * in the CDN/cache, rather than having to generate html for each request and
 * user.
 */
export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, ['common'])),
  },
});
