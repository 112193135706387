import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import {
  ArrowLeftSmall,
  Button,
  ButtonGroup,
  Flex,
  Text,
} from '@lunarway/matter-web';
import { useRouter } from 'next/router';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { View } from '_src/framework/components/view';
import {
  UserData,
  useAddUserData,
  usePostApplication,
  useSubmitApplication,
} from '_src/__codegen__';

import { LoansSectionHousing } from '../../housing/ui/LoansSectionHousing';
import { LoansSectionIntegrations } from '../../integrations/ui/LoansSectionIntegrations';
import { LoansSectionToggles } from '../../_core/ui/LoansSectionToggles';

export interface UserFormData extends UserData {}

export const ApplicationView = () => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const addUserData = useAddUserData();
  const postApplication = usePostApplication();
  const submitApplication = useSubmitApplication();
  const userData = postApplication.data?.userData;

  // const connectedLunar = postApplication.data?.connectedLunar;
  // const connectedESkat = postApplication.data?.connectedESkat;
  // const connectedPsd2 = postApplication.data?.connectedPsd2;
  // const userConnected = connectedLunar && connectedESkat && connectedPsd2;
  // TODO: Implement when Artemis has psd2 implemented: disabled button ->  || !userConnected

  useEffect(() => {
    if (postApplication && userData) {
      methods.reset(userData);
    } else {
      postApplication.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const defaultValues = {
    cars: { cars: undefined },
    children: { children: undefined },
    housing: {
      housingType: undefined,
      livesPrimarilyInDenmark: undefined,
      rent: undefined,
      zipcode: undefined,
    },
    livingExpenses: { isSharing: undefined, splitFactor: undefined },
    loansThisYear: { loans: undefined },
  };

  const methods = useForm<UserFormData>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    formState: { isValid },
    handleSubmit,
    getValues,
  } = methods;

  const currentValues = getValues();
  const isFormFilled =
    currentValues.housing !== undefined &&
    currentValues.cars.hasCars !== undefined &&
    currentValues.housing.zipcode !== undefined &&
    currentValues.housing.housingType !== undefined &&
    currentValues.children.hasChildren !== undefined &&
    currentValues.livingExpenses.isSharing !== undefined &&
    currentValues.housing.livesPrimarilyInDenmark !== undefined &&
    currentValues.loansThisYear.hasTakenLoansThisYear !== undefined;

  const submitForm: SubmitHandler<UserFormData> = (data) => {
    addUserData.mutate(data);
    submitApplication.mutate();
  };

  const handleFormSubmit = () => {
    handleSubmit(submitForm)();
  };

  const handleOnBlur = () => {
    addUserData.mutate(getValues());
  };

  if (submitApplication.status === 'pending') {
    router.push('/application/processing');
  }

  if (submitApplication.status === 'success') {
    setTimeout(() => {
      // TODO: Enhance to production level when having the appropiate API endpoints
      router.push('/application/approved');
    }, 2000);
  }

  return (
    <View
      actions={
        <Button
          variant="secondary"
          adornmentStart={<ArrowLeftSmall />}
          onClick={router.back}
          size="small"
        >
          Return
        </Button>
      }
    >
      <Flex gap={800} flexDirection="column">
        <Flex flexDirection="column" gap={100}>
          <Text fontSize="titleL">Get a non-binding offer.</Text>
          <Text>
            We need to know a little bit about you, so we can make a credit
            assessment, and then give you an offer. It shouldn&apos;t take more
            than 5 minutes, and of course is non-binding.
          </Text>
        </Flex>
        <FormProvider {...methods}>
          <form onBlur={handleOnBlur}>
            <Flex gap={500} flexDirection="column">
              <LoansSectionHousing />
              <LoansSectionToggles currentValues={currentValues} />
              <Flex flexDirection="column" gap={1000}>
                <LoansSectionIntegrations
                  postApplication={postApplication.data}
                />
                <Flex alignSelf="flex-end">
                  <ButtonGroup>
                    <Button
                      variant="secondary"
                      onClick={() => router.push('/application/cancel')}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      disabled={!isFormFilled || !isValid}
                      onClick={handleFormSubmit}
                    >
                      {t('foo')}
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </View>
  );
};
