import { LoanSection } from '../../_core/ui/LoanSection';

import { LoansHousingRent } from './LoansHousingRent';
import { LoansHousingLocation } from './LoansHousingLocation';
import { LoansHousingOptions } from './LoansHousingOptions';

export const LoansSectionHousing = () => (
  <LoanSection
    title="How is your housing situation?"
    description="We need to know what your current housing situation is."
  >
    <LoansHousingOptions />
    <LoansHousingRent />

    <LoansHousingLocation />
  </LoanSection>
);
