import { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonGroup, Flex } from '@lunarway/matter-web';

interface SectionToggleProps {
  isToggled: boolean;
  children?: ReactNode;
  onToggle: (isToggled: boolean) => void;
}

export const SectionToggle = ({
  isToggled,
  children,
  onToggle,
}: SectionToggleProps) => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(
    getInitialButtonIndex(isToggled)
  );

  useEffect(() => {
    setSelectedButtonIndex(getInitialButtonIndex(isToggled));
  }, [isToggled]);

  const handleButtonClick = (index: number) => {
    if (index !== selectedButtonIndex) {
      setSelectedButtonIndex(index);
      onToggle(index === 0);
    }
  };

  return (
    <Flex flexDirection="column" gap={300}>
      <ButtonGroup>
        <Button
          onClick={() => handleButtonClick(0)}
          variant={selectedButtonIndex === 0 ? 'primary' : 'secondary'}
        >
          Yes
        </Button>
        <Button
          onClick={() => handleButtonClick(1)}
          variant={selectedButtonIndex === 1 ? 'primary' : 'secondary'}
        >
          No
        </Button>
      </ButtonGroup>
      {selectedButtonIndex === 0 && children}
    </Flex>
  );
};

const getInitialButtonIndex = (toggle: boolean | undefined) => {
  if (toggle === undefined) {
    return -1;
  }

  if (toggle) {
    return 0;
  }

  return 1;
};
