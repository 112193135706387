import { BinSmall, Button } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

interface DeleteItemButtonProps {
  disabled: boolean;
  margin?: boolean;
  onClick: () => void;
}

export const DeleteItemButton = ({
  disabled,
  margin,
  onClick,
}: DeleteItemButtonProps) => (
  <StyledButton
    isAction
    disabled={disabled}
    $margin={margin}
    variant="tertiary"
    adornmentStart={<BinSmall />}
    onClick={onClick}
  />
);

const StyledButton = styled(Button)<{ $margin?: boolean }>`
  ${({ theme, $margin }) => css`
    ${$margin &&
    css`
      margin-bottom: ${theme.tokens.spaces[50]};
    `}
  `}
`;
