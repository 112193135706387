import React from 'react';
import { useController } from 'react-hook-form';

import { SectionToggle } from '_src/framework/components/button_toggle/SectionToggle';
import { AddMoreButton } from '_src/framework/components/add_more_button/AddMoreButton';
import { ChildrenEnum } from '_src/__codegen__/models/ChildrenEnum';

import { LoanSection } from '../../_core/ui/LoanSection';

import { LoansChildrenItem } from './LoansChildrenItem';

export const LoansSectionChildren = () => {
  const {
    field: { onChange, value },
  } = useController({
    name: 'children',
  });

  const handleToggleChange = (hasChildren: boolean) => {
    onChange({
      ...value,
      children: hasChildren ? [undefined] : [],
      hasChildren,
    });
  };

  const addItem = () => {
    onChange({ ...value, children: [...value.children, ''] });
  };

  const updateItem = (index: number, newValue: ChildrenEnum) => {
    const newChildren = value.children.map((child: any, i: number) =>
      i === index && newValue ? newValue : child
    );

    onChange({
      ...value,
      children: newChildren,
      hasChildren: newChildren.some((child: null) => child !== null),
    });
  };

  const removeItem = (index: number) => {
    onChange({
      ...value,
      children: value.children.filter((_: any, i: number) => i !== index),
    });
  };

  return (
    <LoanSection title="Do you have any children?" gap={200}>
      <SectionToggle
        onToggle={handleToggleChange}
        isToggled={value.hasChildren}
      >
        {value.children?.map((child: string, index: number) => (
          <LoansChildrenItem
            key={index as number}
            value={child as ChildrenEnum}
            disabled={value.children.length === 1}
            removeItem={() => removeItem(index as number)}
            updateItem={(newValue: ChildrenEnum) =>
              updateItem(index as number, newValue)
            }
          />
        ))}
        <AddMoreButton onClick={addItem} />
      </SectionToggle>
    </LoanSection>
  );
};
