import { Button, ButtonGroup, PlusLarge } from '@lunarway/matter-web';

interface AddMoreButtonProps {
  onClick: () => void;
}

export const AddMoreButton = ({ onClick }: AddMoreButtonProps) => (
  <ButtonGroup>
    <Button onClick={onClick} variant="tertiary" adornmentStart={<PlusLarge />}>
      Add more
    </Button>
  </ButtonGroup>
);
