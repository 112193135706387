import { Flex, TextField } from '@lunarway/matter-web';
import styled from 'styled-components';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { useCityFetch } from '../domain/useCityFetch';
import { cleanInputValue } from '../../_core/domain/utils';

export const LoansHousingLocation = () => {
  const { setValue } = useFormContext();
  const postalCode = useWatch({ name: 'housing.zipcode' });
  const { city, cityError } = useCityFetch(postalCode);

  const {
    field: { onChange, value: zipValue = '' },
    fieldState: { error },
  } = useController({
    name: 'housing.zipcode',
    rules: {
      required: 'This field is required',
      validate: {
        validLength: (value) =>
          value.toString().length === 4 || 'Zipcode must be exactly 4 digits',
        validCity: () => !cityError,
      },
    },
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = cleanInputValue(event.target.value);
    const numericValue = cleanedValue !== '' ? Number(cleanedValue) : '';
    onChange(numericValue);
    setValue('housing.zipcode', numericValue);
  };

  return (
    <Flex gap={200}>
      <StyledPostalCodeField
        maxLength={4}
        placeholder="0000"
        label="Postal code"
        aria-label="Postal code"
        errorMessage={cityError || error?.message}
        value={zipValue}
        onChange={handleOnChange}
      />
      <StyledCityField
        isReadOnly
        label="City"
        value={city}
        aria-label="City"
        placeholder="City"
        isDisabled
      />
    </Flex>
  );
};

const StyledPostalCodeField = styled(TextField)`
  width: 120px;
`;

const StyledCityField = styled(TextField)`
  flex-grow: 1;
`;
