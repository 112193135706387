import { useState, useEffect } from 'react';
import { TextField } from '@lunarway/matter-web';
import styled from 'styled-components';
import { useController, useWatch } from 'react-hook-form';

import { HousingEnum } from '_src/__codegen__/models/HousingEnum';

import { cleanInputValue, formatDisplayValue } from '../../_core/domain/utils';

export const LoansHousingRent = () => {
  const housingType = useWatch({ name: 'housing.housingType' });
  const showRent = housingType !== HousingEnum.noRent;
  const [isFocused, setIsFocused] = useState(false);
  const {
    field: { onChange, value = '' },
    fieldState: { error },
  } = useController({
    name: 'housing.rent',
    rules: { required: showRent ? 'This field is required' : false },
  });

  useEffect(() => {
    if (housingType === HousingEnum.noRent) {
      onChange(undefined);
    } else if (value === undefined) {
      onChange(null);
    }
  }, [housingType, onChange, value]);

  return (
    showRent && (
      <StyledTextField
        placeholder="0 kr."
        label="Monthly rent"
        aria-label="Monthly rent"
        value={isFocused ? value : formatDisplayValue(value) || ''}
        onFocus={() => setIsFocused(true)}
        errorMessage={error?.message}
        onBlur={() => {
          setIsFocused(false);
        }}
        onChange={({ target: { value: targetValue } }) => {
          const cleanedValue = cleanInputValue(targetValue);
          onChange(cleanedValue !== '' ? Number(cleanedValue) : cleanedValue);
        }}
      />
    )
  );
};

const StyledTextField = styled(TextField)`
  flex-grow: 1;
`;
