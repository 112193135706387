import React, { ReactNode } from 'react';
import {
  Button,
  CheckmarkCircleFilledSmall,
  CloseCircleLarge,
  Flex,
  HouseLarge,
  MultipleUsersLarge,
  TallBuildingLarge,
  Text,
  focusRingOffset,
} from '@lunarway/matter-web';
import { useController } from 'react-hook-form';
import styled, { css, keyframes } from 'styled-components';

import { HousingEnum } from '_src/__codegen__/models/HousingEnum';

export const LoansHousingOptions = () => {
  const {
    field: { onChange, value },
  } = useController({
    name: 'housing.housingType',
  });

  const iconComponents: Record<keyof typeof HousingEnum, ReactNode> = {
    communityHousing: <MultipleUsersLarge />,
    ownerOccupied: <HouseLarge />,
    rental: <TallBuildingLarge />,
    noRent: <CloseCircleLarge />,
  };

  return (
    <StyledGrid>
      {Object.values(HousingEnum).map((option) => (
        <StyledOption
          tabIndex={0}
          key={option}
          variant="secondary"
          $isSelected={option === value}
          onFocus={() => onChange(option)}
        >
          <Flex
            gap={100}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {option === value && <StyledCheckmark />}
            {iconComponents[option]}
            <Text fontSize="tiny" fontWeight="medium">
              {HOUSING_MAP[option]}
            </Text>
          </Flex>
        </StyledOption>
      ))}
    </StyledGrid>
  );
};

const HOUSING_MAP: Record<keyof typeof HousingEnum, string> = {
  communityHousing: 'Community housing',
  ownerOccupied: 'Home owner',
  rental: 'Rental property',
  noRent: 'I do not pay rent',
};

const StyledGrid = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.tokens.spaces[150]};
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `
);

type StyledOptionProps = {
  $isSelected: boolean;
};

const StyledOption = styled(Button)<StyledOptionProps>(
  ({ theme, $isSelected }) => css`
    flex: 1;
    display: flex;
    width: 100%;
    height: 92px;
    position: relative;
    padding: ${theme.tokens.sizes[200]};
    border-radius: ${theme.tokens.sizes[150]};
    box-shadow: ${theme.shadows[1]};

    ${$isSelected &&
    css`
      ${focusRingOffset(theme.surfacePrimary)}
    `}
  `
);

const popOutAnimation = keyframes`
  0% {
    transform: scale(0.5);

  }
  40% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledCheckmark = styled(CheckmarkCircleFilledSmall)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.tokens.spaces[100]};
    left: ${theme.tokens.spaces[100]};
    animation: ${popOutAnimation} 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  `
);
