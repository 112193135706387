import React from 'react';
import { ComboBox, ComboBoxOption, Flex } from '@lunarway/matter-web';
import styled from 'styled-components';

import { ChildrenEnum } from '_src/__codegen__';
import { DeleteItemButton } from '_src/framework/components/delete_item_button/DeleteItemButton';

interface LoansChildrenItemProps {
  value: ChildrenEnum;
  disabled: boolean;
  updateItem: (value: ChildrenEnum) => void;
  removeItem: () => void;
}

export const LoansChildrenItem = ({
  value,
  disabled,
  updateItem,
  removeItem,
}: LoansChildrenItemProps) => (
  <Flex flexDirection="column" gap={300}>
    <Flex alignItems="center" gap={200}>
      <StyledComboBox
        aria-label="Children"
        placeholder="Add a child"
        isSearchable={false}
        value={CHILD_MAP[value] || ''}
        onChange={(selectedValue) => {
          const selectedKey = Object.keys(CHILD_MAP).find(
            (key) => CHILD_MAP[key as ChildrenEnum] === selectedValue
          );

          if (selectedKey) {
            updateItem(selectedKey as ChildrenEnum);
          }
        }}
      >
        {Object.keys(CHILD_MAP).map((option) => (
          <ComboBoxOption
            key={option}
            value={CHILD_MAP[option as ChildrenEnum]}
          >
            {CHILD_MAP[option as ChildrenEnum]}
          </ComboBoxOption>
        ))}
      </StyledComboBox>
      <DeleteItemButton disabled={disabled} onClick={removeItem} />
    </Flex>
  </Flex>
);

const CHILD_MAP: Record<ChildrenEnum, string> = {
  'child-category-1': '0-1 years old',
  'child-category-2': '2-6 years old',
  'child-category-3': '7-18 years old',
};

const StyledComboBox = styled(ComboBox)`
  flex-grow: 1;
`;
