import React from 'react';
import { useController } from 'react-hook-form';
import { Flex } from '@lunarway/matter-web';

import { SectionToggle } from '_src/framework/components/button_toggle/SectionToggle';
import { AddMoreButton } from '_src/framework/components/add_more_button/AddMoreButton';
import { DeleteItemButton } from '_src/framework/components/delete_item_button/DeleteItemButton';

import { LoanSection } from '../../_core/ui/LoanSection';

import { LoansCurrentLoanTextFields } from './LoansCurrentLoanTextFields';

export const LoansSectionCurrentLoan = () => {
  const {
    field: { onChange, value },
  } = useController({ name: 'loansThisYear' });

  const handleToggleChange = (hasTakenLoansThisYear: boolean) => {
    onChange({
      ...value,
      loans: hasTakenLoansThisYear
        ? [{ amount: null, monthlyPayment: null }]
        : [],
      hasTakenLoansThisYear,
    });
  };

  const addItem = () => {
    onChange({ ...value, loans: [...value.loans, ''] });
  };

  const updateItem = (
    index: number,
    field: 'amount' | 'monthlyPayment',
    newValue: string
  ) => {
    const newLoans = value.loans.map((loan: any, i: number) =>
      i === index ? { ...loan, [field]: Number(newValue) } : loan
    );

    onChange({
      ...value,
      loans: newLoans,
      hasTakenLoansThisYear: newLoans.some((loan: null) => loan !== null),
    });
  };

  const removeItem = (index: number) => {
    onChange({
      ...value,
      loans: value.loans.filter((_: any, i: number) => i !== index),
    });
  };

  return (
    <LoanSection
      title="Have you taken any new loans in 2023?"
      description="We can automatically import loans taken prior to that."
      gap={200}
    >
      <SectionToggle
        onToggle={handleToggleChange}
        isToggled={value.hasTakenLoansThisYear}
      >
        {value.loans?.map(
          (loan: { amount: string; monthlyPayment: string }, index: number) => (
            <Flex key={index as number} alignItems="flex-end" gap={200}>
              <LoansCurrentLoanTextFields
                value={loan?.amount}
                field="amount"
                label="Total loan"
                updateLoan={(newValue) => updateItem(index, 'amount', newValue)}
              />
              <LoansCurrentLoanTextFields
                value={loan?.monthlyPayment}
                field="monthlyPayment"
                label="Monthly payment"
                updateLoan={(newValue) =>
                  updateItem(index, 'monthlyPayment', newValue)
                }
              />
              <DeleteItemButton
                margin
                disabled={value.loans.length === 1}
                onClick={() => removeItem(index as number)}
              />
            </Flex>
          )
        )}
        <AddMoreButton onClick={addItem} />
      </SectionToggle>
    </LoanSection>
  );
};
