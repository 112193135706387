import { Divider, Flex } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

import { UserData } from '_src/__codegen__/models/UserData';

import { LoansSectionDanishResident } from '../../danish_resident/ui/LoansSectionDanishResident';
import { LoansSectionExpenses } from '../../expenses/ui/LoansSectionExpenses';
import { LoansSectionVehicles } from '../../vehicle/ui/LoansSectionVehicles';
import { LoansSectionChildren } from '../../children/ui/LoansSectionChildren';
import { LoansSectionCurrentLoan } from '../../current_loan/ui/LoansSectionCurrentLoan';

interface LoansSectionTogglesProps {
  currentValues: UserData;
}

export const LoansSectionToggles = ({
  currentValues,
}: LoansSectionTogglesProps) => {
  const firstSection =
    currentValues.housing &&
    currentValues.housing.zipcode &&
    currentValues.housing.housingType;

  const secondSection =
    firstSection && currentValues.housing.livesPrimarilyInDenmark !== undefined;

  return (
    <>
      <StyledFlex
        gap={500}
        flexDirection="column"
        $isDirty={!!firstSection}
        inert={firstSection ? undefined : ''}
      >
        <Divider />
        <LoansSectionDanishResident />
        <Divider />
        <LoansSectionExpenses />
      </StyledFlex>
      <StyledFlex
        gap={500}
        flexDirection="column"
        $isDirty={!!secondSection}
        inert={secondSection ? undefined : ''}
      >
        <Divider />
        <LoansSectionVehicles />
        <Divider />
        <LoansSectionChildren />
        <Divider />
        <LoansSectionCurrentLoan />
        <Divider />
      </StyledFlex>
    </>
  );
};

const StyledFlex = styled(Flex)<{ $isDirty: boolean; inert?: string }>`
  ${({ $isDirty, inert }) => css`
    opacity: ${$isDirty ? 1 : 0.5};

    ${inert &&
    css`
      & > * {
        opacity: 0.5;
        pointer-events: none;
      }
    `}
  `}
`;
