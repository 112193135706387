import React from 'react';
import { ComboBox, ComboBoxOption, Flex } from '@lunarway/matter-web';
import styled from 'styled-components';

import { CarEnum } from '_src/__codegen__';
import { DeleteItemButton } from '_src/framework/components/delete_item_button/DeleteItemButton';

interface LoansVehiclesItemProps {
  value: CarEnum;
  disabled: boolean;
  updateItem: (value: CarEnum) => void;
  removeItem: () => void;
}

export const LoansVehiclesItem = ({
  value,
  disabled,
  updateItem,
  removeItem,
}: LoansVehiclesItemProps) => (
  <Flex flexDirection="column" gap={300}>
    <Flex alignItems="center" gap={200}>
      <StyledComboBox
        aria-label="Vehicles"
        placeholder="Add a vehicle"
        isSearchable={false}
        value={CAR_MAP[value] || ''}
        onChange={(selectedValue) => {
          const selectedKey = Object.keys(CAR_MAP).find(
            (key) => CAR_MAP[key as CarEnum] === selectedValue
          );

          if (selectedKey) {
            updateItem(selectedKey as CarEnum);
          }
        }}
      >
        {Object.keys(CAR_MAP).map((option) => (
          <ComboBoxOption key={option} value={CAR_MAP[option as CarEnum]}>
            {CAR_MAP[option as CarEnum]}
          </ComboBoxOption>
        ))}
      </StyledComboBox>
      <DeleteItemButton disabled={disabled} onClick={removeItem} />
    </Flex>
  </Flex>
);

const CAR_MAP: Record<CarEnum, string> = {
  own: 'I own it',
  lease: 'I lease it',
  companyCar: "It's a company car",
};

const StyledComboBox = styled(ComboBox)`
  flex-grow: 1;
`;
