import {
  Button,
  CheckmarkCircleFilledSmall,
  Flex,
  MitidLogo,
  Text,
} from '@lunarway/matter-web';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

import { PostApplicationMutationResponse } from '_src/__codegen__/models/PostApplication';
import { useInitiateEskatConsent } from '_src/__codegen__/hooks/useInitiateEskatConsent';
import { useInitiatePsd2Consent } from '_src/__codegen__/hooks/useInitiatePsd2Consent';

import { ConnectionEnum } from '../../_core/domain/types';

interface LoansIntegrationsButtonProps {
  connection: ConnectionEnum;
  postApplication?: PostApplicationMutationResponse;
}

export const LoansIntegrationsButton = ({
  connection,
  postApplication,
}: LoansIntegrationsButtonProps) => {
  const router = useRouter();
  const eSkatConsentMutation = useInitiateEskatConsent();
  const psd2ConsentMutation = useInitiatePsd2Consent();
  const { connectedLunar, connectedESkat, connectedPsd2 } =
    postApplication || {};

  const isMitID = connection === ConnectionEnum.MitID;
  const isPSD2 = connection === ConnectionEnum.Bank;
  const isESKAT = connection === ConnectionEnum.eSkat;

  const handleEidSignin = () => {
    signIn('lunar-eid', {
      callbackUrl: '/api/eid-verify',
    });
  };

  const handleEskatConnection = async () => {
    try {
      eSkatConsentMutation.mutate();

      if (eSkatConsentMutation.isSuccess && eSkatConsentMutation.data.url) {
        window.location.href = eSkatConsentMutation.data.url;
      } else {
        throw new Error(eSkatConsentMutation.error);
      }
    } catch (error) {
      await router.push('/application/unknown-issue');
    }
  };

  const handlePsd2Connection = async () => {
    try {
      psd2ConsentMutation.mutate();

      if (psd2ConsentMutation.isSuccess && psd2ConsentMutation.data.url) {
        window.location.href = psd2ConsentMutation.data.url;
      } else {
        throw new Error(psd2ConsentMutation.error);
      }
    } catch (error) {
      await router.push('/application/unknown-issue');
    }
  };

  if (isMitID && !connectedLunar) {
    return (
      <Button adornmentStart={<MitidLogo />} onClick={handleEidSignin}>
        Verify
      </Button>
    );
  }

  if (isPSD2 && !connectedPsd2) {
    return (
      <Button
        variant="primary"
        disabled={!connectedLunar}
        onClick={handlePsd2Connection}
      >
        Connect
      </Button>
    );
  }

  if (isESKAT && !connectedESkat) {
    return (
      <Button
        variant="primary"
        disabled={!connectedLunar}
        onClick={handleEskatConnection}
      >
        Connect
      </Button>
    );
  }

  return (
    <Flex gap={50} alignItems="center">
      <CheckmarkCircleFilledSmall />
      <Text fontSize="small" variant="secondary">
        {isMitID ? 'Verified' : 'Connected'}
      </Text>
    </Flex>
  );
};
