import { Flex, FlexProps, Text } from '@lunarway/matter-web';
import { ReactNode } from 'react';

interface LoanSectionProps {
  title: string;
  children: ReactNode;
  description?: string;
  details?: string;
  gap?: FlexProps['gap'];
}

export const LoanSection = ({
  title,
  description,
  children,
  details,
  gap = 300,
}: LoanSectionProps) => (
  <Flex flexDirection="column" gap={gap}>
    <Flex flexDirection="column" gap={100}>
      <Text fontSize="titleS" fontWeight="medium">
        {title}
      </Text>
      <Text fontSize="small" variant="secondary">
        {description}
      </Text>
      {details && (
        <Text fontSize="small" variant="secondary">
          {details}
        </Text>
      )}
    </Flex>
    {children}
  </Flex>
);
