import React from 'react';
import { Slider, Text } from '@lunarway/matter-web';
import { useController } from 'react-hook-form';

import { SectionToggle } from '_src/framework/components/button_toggle/SectionToggle';

import { LoanSection } from '../../_core/ui/LoanSection';

export const LoansSectionExpenses = () => {
  const {
    field: { onChange: onLivingExpensesChange, value: livingExpenses },
  } = useController({
    name: 'livingExpenses',
  });

  const handleSliderChange = (value: Array<number>) => {
    onLivingExpensesChange({
      ...livingExpenses,
      splitFactor: value[0],
    });
  };

  const handleToggleChange = (isSharing: boolean) => {
    onLivingExpensesChange({
      ...livingExpenses,
      isSharing,
      splitFactor: isSharing ? 0 : undefined,
    });
  };

  return (
    <LoanSection
      title="Do you share living expenses?"
      description="Do you share general living expenses with someone? Things like cars, groceries or insurances."
    >
      <SectionToggle
        onToggle={handleToggleChange}
        isToggled={livingExpenses.isSharing}
      >
        <Text>My share is about: {livingExpenses.splitFactor}%</Text>
        <Slider
          step={10}
          max={100}
          value={[livingExpenses.splitFactor]}
          onValueChange={handleSliderChange}
        />
      </SectionToggle>
    </LoanSection>
  );
};
