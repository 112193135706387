import { useState, useEffect } from 'react';

export const useCityFetch = (postalCode: string | undefined) => {
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const postalCodeString = String(postalCode);
      if (postalCode && postalCodeString.length === 4) {
        try {
          const response = await fetch(
            `https://api.dataforsyningen.dk/postnumre/${postalCodeString}`
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          if (data && data.navn) {
            setCity(data.navn);
          }
        } catch (error) {
          setCityError('City not found');
        }
      } else {
        setCity('');
        setCityError('');
      }
    };

    fetchData();
  }, [postalCode]);

  return { city, cityError };
};
