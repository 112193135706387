import React, { useState } from 'react';
import { TextField } from '@lunarway/matter-web';
import styled from 'styled-components';

import { formatDisplayValue } from '../../_core/domain/utils';

interface LoansCurrentLoanTextFieldsProps {
  value: string;
  field: 'amount' | 'monthlyPayment';
  label: string;
  updateLoan: (newValue: string) => void;
}

export const LoansCurrentLoanTextFields = ({
  value,
  field,
  label,
  updateLoan,
}: LoansCurrentLoanTextFieldsProps) => {
  const [fieldFocusState, setFieldFocusState] = useState<boolean>(false);
  const TextFieldComponent = field === 'amount' ? StyledTextField : TextField;

  const handleFocus = () => setFieldFocusState(true);
  const handleBlur = () => setFieldFocusState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateLoan(e.target.value);
  };

  return (
    <TextFieldComponent
      aria-label={label}
      label={label}
      placeholder="0 kr."
      value={fieldFocusState ? value : formatDisplayValue(value || '')}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

const StyledTextField = styled(TextField)`
  flex-grow: 1;
  flex-shrink: 0;
`;
