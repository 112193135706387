import { Flex, Text } from '@lunarway/matter-web';

import { PostApplicationMutationResponse } from '_src/__codegen__/models/PostApplication';

import { ConnectionEnum } from '../../_core/domain/types';

import { LoansIntegrationsButton } from './LoansIntegrationsButton';

interface LoansIntegrationsSegmentProps {
  title: string;
  description: string;
  connection: ConnectionEnum;
  postApplication?: PostApplicationMutationResponse;
}

export const LoansIntegrationsSegment = ({
  title,
  description,
  connection,
  postApplication,
}: LoansIntegrationsSegmentProps) => (
  <Flex gap={200} flexDirection="column">
    <Flex flexDirection="column" gap={50}>
      <Text fontWeight="medium">{title}</Text>
      <Text variant="secondary" fontSize="small">
        {description}
      </Text>
    </Flex>
    <Flex>
      <LoansIntegrationsButton
        connection={connection}
        postApplication={postApplication}
      />
    </Flex>
  </Flex>
);
