import { PostApplicationMutationResponse } from '_src/__codegen__';

import { LoanSection } from '../../_core/ui/LoanSection';
import { ConnectionEnum } from '../../_core/domain/types';

import { LoansIntegrationsSegment } from './LoansIntegrationsSegment';

interface LoansSectionIntegrationsProps {
  postApplication?: PostApplicationMutationResponse;
}

export const LoansSectionIntegrations = ({
  postApplication,
}: LoansSectionIntegrationsProps) => {
  const integrations = [
    {
      title: 'Verify with MitID',
      description: 'We are required by law to know who our customers are.',
      connection: ConnectionEnum.MitID,
    },
    {
      title: 'Connect your bank',
      description:
        'By providing a connection to your bank, we can simulate what a budget would look like for you.',
      connection: ConnectionEnum.Bank,
    },
    {
      title: 'Connect eSKAT',
      description:
        'By providing a connection to your taxes, we can compare your expenses to your income automatically, helping with our credit assessment.',
      connection: ConnectionEnum.eSkat,
    },
  ];

  return (
    <LoanSection
      title="Automated input"
      description="To avoid asking so many questions, we have developed integrations with MitID, most banks, and the tax department. So that you can more easily provide the answers we need in order to do a responsible credit assessment."
      details="We will only use your data for this single purpose - nothing else."
    >
      {integrations.map((integration) => (
        <LoansIntegrationsSegment
          key={integration.connection}
          postApplication={postApplication}
          {...integration}
        />
      ))}
    </LoanSection>
  );
};
