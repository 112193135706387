export const formatDisplayValue = (amount: string | number) => {
  // TODO: i18 locale this - maybe use the same as in Terra
  const numberAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

  if (amount === '' || amount === null) {
    return '';
  }
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(numberAmount);
};

export const cleanInputValue = (value: string) => value.replace(/\D/g, '');
