import React from 'react';
import { useController } from 'react-hook-form';

import { SectionToggle } from '_src/framework/components/button_toggle/SectionToggle';

import { LoanSection } from '../../_core/ui/LoanSection';

export const LoansSectionDanishResident = () => {
  const {
    field: { onChange, value },
  } = useController({
    name: 'housing.livesPrimarilyInDenmark',
  });

  return (
    <LoanSection
      title="Do you live primarily in Denmark?"
      description="We can only provide loans for Danish residents."
    >
      <SectionToggle
        isToggled={value}
        onToggle={(isToggled) => {
          onChange(isToggled);
        }}
      />
    </LoanSection>
  );
};
