import React from 'react';
import { useController } from 'react-hook-form';

import { SectionToggle } from '_src/framework/components/button_toggle/SectionToggle';
import { AddMoreButton } from '_src/framework/components/add_more_button/AddMoreButton';
import { CarEnum } from '_src/__codegen__';

import { LoanSection } from '../../_core/ui/LoanSection';

import { LoansVehiclesItem } from './LoansVehiclesItem';

export const LoansSectionVehicles = () => {
  const {
    field: { onChange, value },
  } = useController({
    name: 'cars',
  });

  const handleToggleChange = (hasCars: boolean) => {
    onChange({
      ...value,
      cars: hasCars ? [undefined] : [],
      hasCars,
    });
  };

  const addItem = () => {
    onChange({ ...value, cars: [...value.cars, ''] });
  };

  const updateItem = (index: number, newValue: string) => {
    const newCars = value.cars.map((car: any, i: number) =>
      i === index && newValue ? newValue : car
    );

    onChange({
      ...value,
      cars: newCars,
      hasCars: newCars.some((car: null) => car !== null),
    });
  };

  const removeItem = (index: number) => {
    onChange({
      ...value,
      cars: value.cars.filter((_: any, i: number) => i !== index),
    });
  };

  return (
    <LoanSection
      title="Do you have expenses for one or more cars? It can be either leased or owned."
      gap={200}
    >
      <SectionToggle onToggle={handleToggleChange} isToggled={value.hasCars}>
        {value.cars?.map((car: string, index: number) => (
          <LoansVehiclesItem
            key={index as number}
            value={car as CarEnum}
            disabled={value.cars.length === 1}
            removeItem={() => removeItem(index as number)}
            updateItem={(newValue: string) =>
              updateItem(index as number, newValue)
            }
          />
        ))}
        <AddMoreButton onClick={addItem} />
      </SectionToggle>
    </LoanSection>
  );
};
